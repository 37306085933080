'use strict';

(function() {

    class MainController {

        constructor($scope, currentUser, $rootScope, Vote, template) {
            $scope.template = template.url;
            //Si hay usuario cargamos en el rootscope sus votos
            if (currentUser._id != null) {
              
                Vote.myVotes(currentUser._id).then(function(data) {
               
                    $rootScope.votes = data;
                    $rootScope.totalVoteUser = data.length;

                })
            } else {
              $rootScope.votes = [];
               $rootScope.totalVoteUser=0;
            }
        }
    }

    angular.module('mvpcarrefourApp')
        .controller('MainController', MainController)
        .controller('AllCategories', ['$scope', '_categories',
            function($scope, _categories) {
                $scope.categories = _categories;
            }
        ]);

})();
